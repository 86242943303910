import React, { useEffect, useState } from 'react';
import { Contract } from '@ethersproject/contracts';
import { useWeb3React } from '@web3-react/core';
import { formatUnits } from '@ethersproject/units';
import { POOL_FACTORY_ADDRESS, POOL_FACTORY_ABI, PAIR_ABI, ERC20_ABI } from '../../constants';
import { getProviderOrSigner } from '../../utils';
import { AutoColumn } from '../../components/Column';
import { LightCard } from '../../components/Card';
import { TYPE as TEXT_TYPE } from '../../theme';
import styled from 'styled-components';
import axios from 'axios';
import DoubleCurrencyLogo from '../../components/DoubleLogo';
import { Token, ChainId } from '@kittypunchxyz/punchswap-v2-sdk';
import { ButtonSecondary } from '../../components/Button';
import { Link } from 'react-router-dom';
import { VERIFIED_POOLS } from '../../constants/verifiedPools';

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  margin: 20px 0;
`;

const Table = styled.table`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  background-color: ${({ theme }) => theme.bg3};
  color: ${({ theme }) => theme.text1};
  font-size: 16px;
  text-align: center;
  @media (max-width: 768px) {
    padding: 6px;
    font-size: 14px;
  }
`;

const TableData = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  height: 60px;
  @media (max-width: 768px) {
    padding: 6px;
    font-size: 14px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const OverviewContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 10px;
  }
`;

const OverviewBox = styled.div`
  background-color: ${({ theme }) => theme.bg3};
  padding: 20px;
  border-radius: 10px;
  width: 200px;
  text-align: center;
  @media (max-width: 768px) {
    width: 45%;
  }
`;

const OverviewLabel = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.text1};
  margin-bottom: 10px;
`;

const OverviewValue = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.text1};
`;

const DisclaimerText = styled(TEXT_TYPE.body)`
  font-style: italic;
  text-align: center;
  margin: 30px 0;
  color: ${({ theme }) => theme.text3};
`;

const WarningText = styled(TEXT_TYPE.body)`
  font-style: italic;
  text-align: center;
  margin: 30px 0;
  padding-top: 20px;
  padding-bottom: 20px;
  color: ${({ theme }) => theme.text3};
`;

const PoolTitle = styled(TEXT_TYPE.main)`
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const PaginationButton = styled.button`
  background-color: ${({ theme }) => theme.bg3};
  color: ${({ theme }) => theme.text1};
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 5px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    padding: 8px 15px;
    font-size: 14px;
  }
`;

const CopyButton = ({ text }: { text: string }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    alert('Copied to clipboard!');
  };

  return (
    <span
      onClick={handleCopy}
      style={{ marginLeft: '8px', cursor: 'pointer' }}
      title="Copy Address"
    >
      <span role="img" aria-label="clipboard">
        📋
      </span>
    </span>
  );
};

// Add flowscanLink function
const flowscanLink = (address: string) => `https://evm.flowscan.io/address/${address}`;

// New styled component for the hyperlink
const PairLink = styled.a`
  color: ${({ theme }) => theme.primary1};
  text-decoration: none;
  font-weight: bold;
  margin-left: 8px;

  &:hover {
    text-decoration: underline;
  }
`;

const PoolOverview: React.FC = () => {
  const { library, account, chainId } = useWeb3React();
  const [pools, setPools] = useState<any[]>([]);
  const [verifiedPools, setVerifiedPools] = useState<any[]>([]);
  const [flowPrice, setFlowPrice] = useState<number>(0);
  const [usdcPrice, setUsdcPrice] = useState<number>(1); // Default USDC price is 1
  const [totalTVL, setTotalTVL] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchPools = async () => {
      if (!library || !account || !chainId) return;
    
      const provider = getProviderOrSigner(library, account);
      const factoryContract = new Contract(POOL_FACTORY_ADDRESS, POOL_FACTORY_ABI, provider);
    
      const poolCount = await factoryContract.allPairsLength();
      const poolData: any[] = [];
      const verified: any[] = [];
      let totalTvl = 0;
    
      for (let i = 0; i < poolCount; i++) {
        const pairAddress: string = await factoryContract.allPairs(i);
        const pairContract = new Contract(pairAddress, PAIR_ABI, provider);
        const token0Address = await pairContract.token0();
        const token1Address = await pairContract.token1();
        const reserves = await pairContract.getReserves();
    
        const token0Contract = new Contract(token0Address, ERC20_ABI, provider);
        const token1Contract = new Contract(token1Address, ERC20_ABI, provider);
    
        // Fetch the symbols
        let [symbol0, symbol1] = await Promise.all([
          token0Contract.symbol(),
          token1Contract.symbol(),
        ]);

        // **Adjust decimals for USDC.e**
        let decimals0 = 18;
        let decimals1 = 18;

        if (symbol0 === 'USDC.e') decimals0 = 6;
        if (symbol1 === 'USDC.e') decimals1 = 6;

        // Create token instances with the correct decimals
        const token0 = new Token(chainId as ChainId, token0Address, decimals0, symbol0);
        const token1 = new Token(chainId as ChainId, token1Address, decimals1, symbol1);

        // Format reserves using correct decimals
        const reserve0Formatted = Number(formatUnits(reserves._reserve0, decimals0));
        const reserve1Formatted = Number(formatUnits(reserves._reserve1, decimals1));

        // Calculate TVL and proceed as before
        const poolTVL = calculateTVL(
          reserve0Formatted,
          reserve1Formatted,
          token0,
          token1,
          flowPrice,
          usdcPrice
        );

        totalTvl += poolTVL;

        const poolDetails = {
          pairAddress,
          token0,
          token1,
          reserve0: reserve0Formatted.toString(),
          reserve1: reserve1Formatted.toString(),
          tvl: poolTVL,
        };

        poolData.push(poolDetails);

        // Check for verified pools
        if (VERIFIED_POOLS[pairAddress as keyof typeof VERIFIED_POOLS]) {
          verified.push(poolDetails);
        }
      }
    
      // Update state as before
      setPools(poolData.sort((a, b) => b.tvl - a.tvl));
      setVerifiedPools(verified.sort((a, b) => b.tvl - a.tvl));
      setTotalTVL(totalTvl);
    };    

    const fetchPrices = async () => {
      const response = await axios.get(
        'https://api.coingecko.com/api/v3/simple/price?ids=flow,usd-coin&vs_currencies=usd'
      );
      setFlowPrice(response.data.flow.usd);
      setUsdcPrice(response.data['usd-coin'].usd); // Set USDC price
    };

    fetchPrices().then(() => fetchPools());
  }, [library, account, chainId, flowPrice, usdcPrice]);

  const calculateTVL = (
    reserve0: number,
    reserve1: number,
    token0: any,
    token1: any,
    flowPrice: number,
    usdcPrice: number
  ) => {
    const isUSDC = (symbol: string) => symbol.toUpperCase().includes('USDC'); 
    const isFlow = (symbol: string) => symbol.toUpperCase() === 'FLOW';
    const isWFLOW = (symbol: string) => symbol.toUpperCase() === 'WFLOW';

    // **Specific adjustment for USDC.e-WFLOW pair**
    if (
      (isUSDC(token0.symbol) && isWFLOW(token1.symbol)) ||
      (isWFLOW(token0.symbol) && isUSDC(token1.symbol))
    ) {
      const usdcReserve = isUSDC(token0.symbol) ? reserve0 : reserve1;
      const usdcValue = usdcReserve * usdcPrice;
      const totalValue = usdcValue * 2;
      return totalValue;
    }

    let reserve0Value = reserve0;
    let reserve1Value = reserve1;

    if (isFlow(token0.symbol) && isUSDC(token1.symbol)) {
      reserve0Value = reserve0 * flowPrice;
      reserve1Value = reserve1 * usdcPrice;
      return reserve0Value + reserve1Value;
    } else if (isUSDC(token0.symbol) && isFlow(token1.symbol)) {
      reserve0Value = reserve0 * usdcPrice;
      reserve1Value = reserve1 * flowPrice;
      return reserve0Value + reserve1Value;
    }

    if (isWFLOW(token0.symbol) && isUSDC(token1.symbol)) {
      reserve0Value = reserve0 * flowPrice;
      reserve1Value = reserve1 * usdcPrice;
      return reserve0Value + reserve1Value;
    } else if (isUSDC(token0.symbol) && isWFLOW(token1.symbol)) {
      reserve0Value = reserve0 * usdcPrice;
      reserve1Value = reserve1 * flowPrice;
      return reserve0Value + reserve1Value;
    }

    if (isWFLOW(token0.symbol)) {
      reserve0Value = reserve0 * flowPrice;
      reserve1Value = reserve1;
      return 2 * reserve0Value;
    } else if (isWFLOW(token1.symbol)) {
      reserve1Value = reserve1 * flowPrice;
      reserve0Value = reserve0;
      return 2 * reserve1Value;
    }

    if (isFlow(token0.symbol)) {
      reserve0Value = reserve0 * flowPrice;
      reserve1Value = (reserve1 / reserve0) * reserve0Value;
    } else if (isFlow(token1.symbol)) {
      reserve1Value = reserve1 * flowPrice;
      reserve0Value = (reserve0 / reserve1) * reserve1Value;
    } else {
      return 0;
    }

    return reserve0Value + reserve1Value;
  };

  const handlePageChange = (direction: string) => {
    if (direction === 'prev' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (direction === 'next' && currentPage < Math.ceil(pools.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <AutoColumn gap="lg" justify="center">
      <LightCard padding="40px">
        <OverviewContainer>
          <OverviewBox>
            <OverviewLabel>Flow Price:</OverviewLabel>
            <OverviewValue>
              ${flowPrice.toFixed(2).toLocaleString()}
            </OverviewValue>
          </OverviewBox>
          <OverviewBox>
            <OverviewLabel>USDC Price:</OverviewLabel>
            <OverviewValue>
              ${usdcPrice.toFixed(2).toLocaleString()}
            </OverviewValue>
          </OverviewBox>
          <OverviewBox>
            <OverviewLabel>Total TVL:</OverviewLabel>
            <OverviewValue>
              ${totalTVL.toLocaleString(undefined, { maximumFractionDigits: 2 })}
            </OverviewValue>
          </OverviewBox>
        </OverviewContainer>
        <DisclaimerText>
          Data may not be fully up to date. Full analytics will arrive soon with the onboarding of critical tooling to Flow EVM.
        </DisclaimerText>

        <PoolTitle>Verified Pools</PoolTitle>
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <TableHeader>Pair</TableHeader>
                <TableHeader>TVL (USD)</TableHeader>
                <TableHeader>Trade</TableHeader>
                <TableHeader>Add Liquidity</TableHeader>
              </tr>
            </thead>
            <tbody>
              {verifiedPools.map((pool, index) => (
                <tr key={index}>
                  <TableData style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <DoubleCurrencyLogo currency0={pool.token0} currency1={pool.token1} size={24} margin={true} />
                    {/* Updated pair text with hyperlink */}
                    <PairLink href={flowscanLink(pool.pairAddress)} target="_blank" rel="noopener noreferrer">
                      {pool.token0.symbol}/{pool.token1.symbol}
                    </PairLink>
                    <CopyButton text={pool.pairAddress} />
                  </TableData>
                  <TableData>${pool.tvl.toLocaleString(undefined, { maximumFractionDigits: 2 })}</TableData>
                  <TableData>
                    <ButtonWrapper>
                      <ButtonSecondary as={Link} to={`/swap?inputCurrency=${pool.token0.address}&outputCurrency=${pool.token1.address}`} width="100%">
                        Trade
                      </ButtonSecondary>
                    </ButtonWrapper>
                  </TableData>
                  <TableData>
                    <ButtonWrapper>
                      <ButtonSecondary as={Link} to={`/add/${pool.token0.address}/${pool.token1.address}`} width="100%">
                        Deposit
                      </ButtonSecondary>
                    </ButtonWrapper>
                  </TableData>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableWrapper>

        <PoolTitle>All Pools</PoolTitle>
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <TableHeader>Pair</TableHeader>
                <TableHeader>TVL (USD)</TableHeader>
                <TableHeader>Trade</TableHeader>
                <TableHeader>Add Liquidity</TableHeader>
              </tr>
            </thead>
            <tbody>
              {pools.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((pool, index) => (
                <tr key={index}>
                  <TableData style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <DoubleCurrencyLogo currency0={pool.token0} currency1={pool.token1} size={24} margin={true} />
                    {/* Updated pair text with hyperlink */}
                    <PairLink href={flowscanLink(pool.pairAddress)} target="_blank" rel="noopener noreferrer">
                      {pool.token0.symbol}/{pool.token1.symbol}
                    </PairLink>
                    <CopyButton text={pool.pairAddress} />
                  </TableData>
                  <TableData>${pool.tvl.toLocaleString(undefined, { maximumFractionDigits: 2 })}</TableData>
                  <TableData>
                    <ButtonWrapper>
                      <ButtonSecondary as={Link} to={`/swap?inputCurrency=${pool.token0.address}&outputCurrency=${pool.token1.address}`} width="100%">
                        Trade
                      </ButtonSecondary>
                    </ButtonWrapper>
                  </TableData>
                  <TableData>
                    <ButtonWrapper>
                      <ButtonSecondary as={Link} to={`/add/${pool.token0.address}/${pool.token1.address}`} width="100%">
                        Deposit
                      </ButtonSecondary>
                    </ButtonWrapper>
                  </TableData>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableWrapper>

        <PaginationWrapper>
          <PaginationButton onClick={() => handlePageChange('prev')} disabled={currentPage === 1}>
            Previous
          </PaginationButton>
          <PaginationButton onClick={() => handlePageChange('next')} disabled={currentPage === Math.ceil(pools.length / itemsPerPage)}>
            Next
          </PaginationButton>
        </PaginationWrapper>

        <WarningText>
          Warning: PunchSwap is a permissionless trading platform and anyone can create a pool.
          <br />
          <br />
          For non-verified pools, please exercise caution and confirm addresses before interacting!
        </WarningText>
      </LightCard>
    </AutoColumn>
  );
};

export default PoolOverview;