// components/Header.tsx

import { ChainId } from '@kittypunchxyz/punchswap-v2-sdk';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Text } from 'rebass';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import Logo from '../../assets/images/Punch1.png';
import LogoDark from '../../assets/images/Punch1.png';
import { useActiveWeb3React } from '../../hooks';
import { useDarkModeManager } from '../../state/user/hooks';
import { useETHBalances } from '../../state/wallet/hooks';

import { YellowCard } from '../Card';
import Settings from '../Settings';
import { RowBetween } from '../Row';
import Web3Status from '../Web3Status';

// Main header container
const HeaderFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  position: absolute;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 12px 0 0 0;
    width: calc(100%);
    position: relative;
  `};
`;

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.a`
  display: flex;
  align-items: center;
  margin-right: 2rem;
  :hover {
    cursor: pointer;
  }
`;

const TestnetWrapper = styled.div`
  white-space: nowrap;
  width: fit-content;
  margin-left: 10px;
`;

const NetworkCard = styled(YellowCard)`
  width: fit-content;
  margin-right: 10px;
  border-radius: 12px;
  padding: 8px 12px;
`;

const UniIcon = styled.div`
  width: 50px;
  height: 50px;
  margin-right: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: auto;
  }
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`;

const NavLinkStyled = styled(NavLink)`
  color: ${({ theme }) => theme.text1};
  text-decoration: none;
  margin-right: 16px;
  font-size: 16px;
  &:hover {
    color: ${({ theme }) => theme.primary1};
  }
  &.active {
    font-weight: bold;
  }
`;

const ExternalLinkStyled = styled.a`
  color: ${({ theme }) => theme.text1};
  text-decoration: none;
  margin-right: 16px;
  font-size: 16px;
  &:hover {
    color: ${({ theme }) => theme.primary1};
  }
`;

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`;

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`;

const HamburgerMenu = styled.div`
  display: none;
  cursor: pointer;
  font-size: 24px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
    margin-left: auto;
  `};
`;

const MobileMenu = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? 'block' : 'none')};
  position: absolute;
  top: 60px;
  right: 0;
  background-color: ${({ theme }) => theme.bg1};
  width: 100%;
  height: 100vh;
  z-index: 100;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  a {
    display: block;
    margin: 20px 0;
    font-size: 18px;
    text-align: center;
  }
`;

const HamburgerIcon = styled.div`
  width: 30px;
  height: 3px;
  background-color: ${({ theme }) => theme.text1};
  margin: 6px 0;
`;

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
  :focus {
    border: 1px solid blue;
  }
`;

const NETWORK_LABELS: { [chainId in ChainId]: string | null } = {
  [ChainId.FLOW_MAINNET]: 'Flow EVM',
};

export default function Header() {
  const { account, chainId } = useActiveWeb3React();
  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? ''];
  const [isDark] = useDarkModeManager();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <HeaderFrame>
      <RowBetween style={{ alignItems: 'center' }} padding="1rem 1rem 0 1rem">
        <HeaderElement>
          <Title href=".">
            <UniIcon>
              <img src={isDark ? LogoDark : Logo} alt="logo" />
            </UniIcon>
          </Title>

          {/* Desktop navigation */}
          <NavLinks>
            <NavLinkStyled to="/swap" activeClassName="active">
              Swap
            </NavLinkStyled>
            <NavLinkStyled to="/pool" activeClassName="active">
              Pool
            </NavLinkStyled>
            <NavLinkStyled to="/pool-overview" activeClassName="active">
              Pool Overview
            </NavLinkStyled>
            <ExternalLinkStyled href="https://app.kittypunch.xyz/farms" target="_blank" rel="noopener noreferrer">
              Farms
            </ExternalLinkStyled>
            <NavLinkStyled to="/token-overview" activeClassName="active">
              Token Overview
            </NavLinkStyled>
          </NavLinks>
        </HeaderElement>

        {/* Mobile navigation */}
        <HamburgerMenu onClick={toggleMobileMenu}>
          <HamburgerIcon />
          <HamburgerIcon />
          <HamburgerIcon />
        </HamburgerMenu>

        <MobileMenu open={mobileMenuOpen}>
          <NavLinkStyled to="/swap" activeClassName="active" onClick={toggleMobileMenu}>
            Swap
          </NavLinkStyled>
          <NavLinkStyled to="/pool" activeClassName="active" onClick={toggleMobileMenu}>
            Pool
          </NavLinkStyled>
          <NavLinkStyled to="/pool-overview" activeClassName="active" onClick={toggleMobileMenu}>
            Pool Overview
          </NavLinkStyled>
          <ExternalLinkStyled
            href="https://app.kittypunch.xyz/farms"
            target="_blank"
            rel="noopener noreferrer"
            onClick={toggleMobileMenu}
          >
            Farms
          </ExternalLinkStyled>
          <NavLinkStyled to="/token-overview" activeClassName="active" onClick={toggleMobileMenu}>
            Token Overview
          </NavLinkStyled>

          <div>
            <TestnetWrapper>
              {chainId && NETWORK_LABELS[chainId] && <NetworkCard>{NETWORK_LABELS[chainId]}</NetworkCard>}
            </TestnetWrapper>
            <AccountElement active={!!account}>
              {account && userEthBalance ? (
                <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                  {userEthBalance?.toSignificant(4)} FLOW
                </BalanceText>
              ) : null}
              <Web3Status />
            </AccountElement>
          </div>
        </MobileMenu>

        {/* Desktop Web3 Status */}
        <HeaderControls>
          <TestnetWrapper>
            {!isMobile && chainId && NETWORK_LABELS[chainId] && <NetworkCard>{NETWORK_LABELS[chainId]}</NetworkCard>}
          </TestnetWrapper>
          <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
            {account && userEthBalance ? (
              <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                {userEthBalance?.toSignificant(4)} FLOW
              </BalanceText>
            ) : null}
            <Web3Status />
          </AccountElement>
          <HeaderElement>
            <Settings />
          </HeaderElement>
        </HeaderControls>
      </RowBetween>
    </HeaderFrame>
  );
}