// src/components/CurrencyLogo.tsx
import { Currency, ETHER, Token } from '@kittypunchxyz/punchswap-v2-sdk'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import FlowLogo from '../../assets/images/Flow.png'
import USDCLogo from '../../assets/images/usdc.png'
import FrothLogo from '../../assets/images/froth.png'
import ankrFlowLogo from '../../assets/images/ankrFLOW.png'
import LoppyLogo from '../../assets/images/loppy1.png'
import BetaLogo from '../../assets/images/BETA.png'

import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import Logo from '../Logo'

const getTokenLogoURL = (address: string) =>
  `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${address}/logo.png`

const StyledFlowLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)

  const srcs: string[] = useMemo(() => {
    if (currency === ETHER) return []

    if (currency instanceof Token) {
      if (currency.symbol === 'WFLOW') {
        return [FlowLogo]
      }

      if (currency.symbol === 'USDCf') {
        return [USDCLogo] // Use the custom xyzUSDC logo
      }

      if (currency.symbol === 'USDC.e') {
        return [USDCLogo] // Use the custom xyzUSDC logo
      }

      if (currency.symbol === 'ankrFLOW') {
        return [ankrFlowLogo] // Use the custom ankrFLOW logo
      }

      if (currency.symbol === 'FROTH') {
        return [FrothLogo] // Use the custom ankrFLOW logo
      }

      if (currency.symbol === 'ankrFLOWEVM') {
        return [ankrFlowLogo] // Use the custom ankrFLOW logo
      }

      if (currency.symbol === 'LOPPY') {
        return [LoppyLogo] // Use the custom ankrFLOW logo
      }

      if (currency.symbol === 'BETA') {
        return [BetaLogo] // Use the custom ankrFLOW logo
      }

      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, getTokenLogoURL(currency.address)]
      }

      return [getTokenLogoURL(currency.address)]
    }
    return []
  }, [currency, uriLocations])

  if (currency === ETHER) {
    return <StyledFlowLogo src={FlowLogo} size={size} style={style} />
  }

  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
}